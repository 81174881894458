import { Navigate, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { AUTH_LOCAL_STORAGE_KEY, AuthModel, useAuth } from '../../../auth'
import { useDashboardAffiliateStore } from '../../program/program-affiliate/states/program-affiliate.state'
import { useEffect, useState } from 'react'
import DashboardCardAffiliatorComponent from '../../auth/affiliator-management/components/dashboard-card-affiliator.component'
import { generateCurrencyFormat } from '../../../../helpers/generate-currency-format'
import { KTCard, KTSVG } from '../../../../../_metronic/helpers'
import { LoadingComponent } from '../../crm/properties-management/components/loading.component'
import { useDonationStore } from '../../program/donation/states/donation.state'
import ReactPaginate from 'react-paginate'
import {
  dateQueryFormatter,
  timeDateFormatter,
} from '../../../../../_metronic/helpers/datetime-formatter'
import { generateStatusBadge } from '../../../../../_metronic/helpers/generate-status-badge'
import { SelectData } from '../../../../interfaces/form-action-interfaces'
import { toast } from 'react-toastify'
import {
  Input,
  InputDatePicker,
  SelectInput,
  SelectWithText,
} from '../../../forms/components/input.component'
import { useProspectChannelStore } from '../../crm/prospect-channel-management/states/prospect-channel.state'
import { usePaymentStore } from '../../program/payment/states/payment.state'
import MultiSelect, { Option } from '../../../forms/components/multiple-select.component'
import { useBranchStore } from '../../auth/branch-management/states/branch.state'
import { useCityStore } from '../../auth/cities-management/states/city.state'
import { useUserStore } from '../../auth/user-management/states/user.state'
import { useCustomerStore } from '../../crm/customer-management/states/customer.state'
import { useProgramStore } from '../../program/program/states/program.state'
import { useDashboardStore } from '../states/dashboard.state'
import { useIntl } from 'react-intl'
import DashboardAdminBranchNewComponent from './dashboard-admin-branch-new.screen'

export const dashboardAdminBranchBreadcrumbs: Array<PageLink> = [
  {
    title: 'Admin Cabang',
    path: '/apps/admin-branch-dashboard/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DashboardAdminBranchScreen = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='dashboard'
          element={
            <>
              <DashboardAdminBranchNewComponent />
            </>
          }
        />
        <Route
          path='dashboard/:acronym'
          element={
            <>
              <DashboardAdminBranchComponent />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/admin-branch-dashboard/dashboard' />} />
    </Routes>
  )
}

const DashboardAdminBranchComponent = () => {
  const intl = useIntl()
  const { currentUser } = useAuth()
  const { startDate, endDate, setStartDate, setEndDate } = useDashboardStore()
  const { totalDonationByBranch, totalDonationByBranchLoading, getTotalDonationByBranch } =
    useDashboardAffiliateStore()
  const { customerSelectDatas } = useCustomerStore()
  const { getUsers, userSelectDatas } = useUserStore()
  const { getCities, citySelectDataDonation } = useCityStore()
  const { getPrograms, programSelectDatas } = useProgramStore()
  const { getBranchs, branchSelectDatas } = useBranchStore()

  const { getPayments, paymentSelectDatas } = usePaymentStore()
  const { getProspectChannels, prospectChannelSelectDatas } = useProspectChannelStore()

  const [status, setStatus] = useState<string>()
  const [customerId, setCustomerId] = useState<SelectData>()
  const [programId, setProgramId] = useState<SelectData>()
  const [email, setEmail] = useState<string>()
  const [cityId, setCityId] = useState<SelectData>()
  const [fundraiserId, setFundraiserId] = useState<SelectData>()
  const [programType, setProgramType] = useState<SelectData>()
  const [affiliateCode, setAffiliateCode] = useState<string>()
  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const [branchId, setBranchId] = useState<SelectData>()
  const [paymentId, setPaymentId] = useState<SelectData>()
  const [paymentSelected, setPaymentSelected] = useState<Option[] | null>()
  const [paymentValue, setPaymenValue] = useState<any[] | []>()
  const [reference, setReference] = useState<string>()
  const [prospectChannelId, setProspectChannelId] = useState<SelectData>()
  const [prospectChannelSelected, setProspectChannelSelected] = useState<Option[] | null>()
  const [prospectChannelValue, setProspectChannelValue] = useState<Option[] | null>()
  const [search, setSearch] = useState<string>('')

  const { getDonation, donationModel, donationItems, donationLoading } = useDonationStore()
  const navigate = useNavigate()
  const [activeMenu, setActiveMenu] = useState<string>('dashboard')

  const handlePaymentChange = (selected: Option[]) => {
    setPaymentSelected(selected)
  }

  const handleChannelChange = (selected: Option[]) => {
    setProspectChannelSelected(selected)
  }

  useEffect(() => {
    getTotalDonationByBranch({
      affiliate_code: currentUser?.data?.affiliate_code,
      start_date: startDate?.toDateString() ?? '2025-01-01',
      end_date: endDate?.toDateString() ?? '2025-12-31',
    })
  }, [startDate, endDate])

  useEffect(() => {
    if (totalDonationByBranch !== undefined) {
      getDonation({
        page: 0,
        size: 10,
        start_date: startDate === undefined ? undefined : dateQueryFormatter(startDate),
        end_date: endDate === undefined ? undefined : dateQueryFormatter(endDate),
        status: status,
        customer_id: customerId?.value ?? undefined,
        email: email ?? undefined,
        city_id: cityId?.value ?? undefined,
        fundraiser_id: fundraiserId?.value ?? undefined,
        reference: reference,
        program_type: programType?.value ?? undefined,
        program_id: programId?.value ?? undefined,
        aff_code: affiliateCode ?? undefined,
        branch_id: branchId?.value ?? undefined,
        payment_id: paymentId?.value ?? undefined,
        payment_selected: `${paymentValue}`,
        channel_id: prospectChannelId?.value ?? undefined,
        channel_selected: `${prospectChannelValue}`,
        province_id: totalDonationByBranch?.data?.province?.acronym,
        community_id:
          currentUser?.data?.affiliate_code?.charAt(5) == '1' ||
            currentUser?.data?.affiliate_code?.charAt(5) == '5'
            ? currentUser?.data?.affiliate_code?.charAt(5)
            : '',
        class_code:
          currentUser?.data?.affiliate_code?.charAt(5) !== '1' &&
            currentUser?.data?.affiliate_code?.charAt(5) !== '5'
            ? currentUser?.data?.affiliate_code
            : '',
        sort_amount: undefined
      })

      getPrograms({
        page: 0,
        size: 50,
      })
      getBranchs({
        page: 0,
        size: 50,
      })
      // remove role user
      getUsers({
        page: 0,
        size: 25,
        filters: `["role_id", "<>", "7961fc62-ea25-4fd0-840e-945333ff7b14"]`,
      })
      getPayments({
        page: 0,
        size: 10,
      })
      getProspectChannels({
        page: 0,
        size: 25,
      })
    }
  }, [totalDonationByBranch, endDate, startDate])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getDonation({
      page: selectedPage,
      size: 10,
      start_date: startDate === undefined ? undefined : dateQueryFormatter(startDate),
      end_date: endDate === undefined ? undefined : dateQueryFormatter(endDate),
      status: status,
      customer_id: customerId?.value ?? undefined,
      email: email ?? undefined,
      city_id: cityId?.value ?? undefined,
      fundraiser_id: fundraiserId?.value ?? undefined,
      reference: reference,
      program_type: programType?.value ?? undefined,
      program_id: programId?.value ?? undefined,
      aff_code: affiliateCode ?? undefined,
      branch_id: branchId?.value ?? undefined,
      payment_id: paymentId?.value ?? undefined,
      payment_selected: `${paymentValue}`,
      channel_id: prospectChannelId?.value ?? undefined,
      channel_selected: `${prospectChannelValue}`,
      province_id: totalDonationByBranch?.data?.province?.acronym,
      community_id:
        currentUser?.data?.affiliate_code?.charAt(5) == '1' ||
          currentUser?.data?.affiliate_code?.charAt(5) == '5'
          ? currentUser?.data?.affiliate_code?.charAt(5)
          : '',
      class_code:
        currentUser?.data?.affiliate_code?.charAt(5) !== '1' &&
          currentUser?.data?.affiliate_code?.charAt(5) !== '5'
          ? currentUser?.data?.affiliate_code
          : '',
      sort_amount: undefined
    })
  }

  return (
    <>

      {/* <PageTitle breadcrumbs={dashboardAdminBranchBreadcrumbs}>
        {`DASHBOARD - ${totalDonationByBranch?.data?.province?.name} - ${currentUser?.data?.email}`}
      </PageTitle> */}

      <div className='col-12' style={{ marginBottom: -20 }}>
        <div className='row'>
          <div className='col-2'>
            <InputDatePicker
              id={'start_date'}
              title={'Start Date'}
              name={'start_date'}
              type={'date'}
              value={startDate}
              onChangeDate={(e) => {
                setStartDate(e)
              }}
            />
          </div>
          <div className='col-2'>
            <InputDatePicker
              id={'end_date'}
              title={'End Date'}
              name={'end_date'}
              type={'date'}
              value={endDate}
              onChangeDate={(e) => {
                setEndDate(e)
              }}
            />
          </div>
        </div>
      </div>
      <div className='row gy-5 g-xl-8 mb-5'>
        <DashboardCardAffiliatorComponent
          title={`Jumlah ${intl.formatMessage({ id: 'APPS.TRANSACTION' })}`}
          color='success'
          value={totalDonationByBranch?.data?.total_donations_in_trx}
          subtitle={`Jumlah ${intl.formatMessage({ id: 'APPS.TRANSACTION' })}`}
          loading={totalDonationByBranchLoading}
          className='col-sm-4 col-xl-4'
          onClick={(e) => {
            e.preventDefault()
            setActiveMenu('donation')
          }}
        />
        <DashboardCardAffiliatorComponent
          title={`${intl.formatMessage({ id: 'APPS.TRANSACTION' })} Terkumpul`}
          color='success'
          value={generateCurrencyFormat(totalDonationByBranch?.data?.total_donations_in_amount)}
          subtitle={`${intl.formatMessage({ id: 'APPS.TRANSACTION' })} Terkumpul`}
          loading={totalDonationByBranchLoading}
          className='col-sm-4 col-xl-4'
          onClick={(e) => {
            e.preventDefault()
            setActiveMenu('donation')
          }}
        />
        <DashboardCardAffiliatorComponent
          title='Jumlah Relawan'
          color='success'
          value={totalDonationByBranch?.data?.province?.total_volunteer}
          subtitle='Jumlah Relawan'
          loading={totalDonationByBranchLoading}
          className='col-sm-4 col-xl-4'
          onClick={(e) => {
            e.preventDefault()
            setActiveMenu('dashboard')
          }}
        />
      </div>

      {activeMenu === 'dashboard' ? (
        <KTCard>
          <div className='card-header border-0 pt-6'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Subbranch</span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              {totalDonationByBranchLoading ? <LoadingComponent /> : <></>}
              <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                <thead className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <tr>
                    <th>No</th>
                    <th>Nama</th>
                    <th>Total Donasi</th>
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-semibold'>
                  {totalDonationByBranch?.data?.cities?.length === 0 ? (
                    <tr>
                      <td colSpan={10}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No Properties Found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    totalDonationByBranch?.data?.cities?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <a
                              href='#'
                              onClick={(e) => {
                                e.preventDefault()
                                navigate('/apps/admin-subbranch-dashboard/dashboard', {
                                  state: {
                                    province_code: totalDonationByBranch?.data?.province?.acronym,
                                    city_code: item.acronym,
                                    community_code: totalDonationByBranch?.data?.community_code,
                                  },
                                })
                              }}
                            >
                              {item?.name?.toUpperCase()}
                            </a>
                          </td>
                          <td>{generateCurrencyFormat(item?.total_donation)}</td>
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </KTCard>
      ) : null}

      {activeMenu === 'donation' ? (
        <KTCard className='mt-10'>
          <div className='alert alert-primary d-flex align-items-center p-5'>
            <div className='d-flex flex-column'>
              <h4 className='mb-1 text-primary'>Perhatian</h4>
              <span>Data yang ditampilkan pada saat awal adalah data 30 hari kebelakang</span>
            </div>
          </div>
          <div className='py-5'>
            <div className='rounded border p-5' style={{ backgroundColor: 'white' }}>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-3'>
                    <SelectWithText
                      title='Program Type'
                      name='program_type'
                      selectData={[
                        { value: 'Kurban', label: 'Kurban' },
                        { value: 'Donasi', label: 'Donasi' },
                      ]}
                      type='select-with-text'
                      value={programType}
                      onChangeSelectWithText={(e) => {
                        setProgramType(e)
                      }}
                      id={''}
                    />
                  </div>
                  <div className='col-3'>
                    <SelectWithText
                      title='Fundraiser'
                      name='fundraiser'
                      selectData={userSelectDatas}
                      type='select-with-text'
                      value={fundraiserId}
                      onChangeSelectWithText={(e) => {
                        setFundraiserId(e)
                      }}
                      onInputChange={(e) => {
                        getUsers({
                          page: 0,
                          size: 25,
                          search: e,
                          filters: `["role_id", "<>", "7961fc62-ea25-4fd0-840e-945333ff7b14"]`,
                        })
                      }}
                      id={''}
                    />
                  </div>
                  <div className='col-6'>
                    <SelectWithText
                      title='Donatur (Search By Email, Name, Hp)'
                      name='donatur'
                      selectData={customerSelectDatas}
                      type='select-with-text'
                      value={customerId}
                      onChangeSelectWithText={(e) => {
                        setCustomerId(e)
                      }}
                      onInputChange={(e) => {
                        setSearch(e)
                      }}
                      id={''}
                    />
                  </div>
                  {currentUser?.data?.company?.id === '468f9204-82ac-4ea7-a60c-0bd1e81eb636' ||
                    currentUser?.data?.company?.id === 'ba4bc43d-bb0e-4902-acad-56e9c14c4ef4' ? (
                    <div className='col-3'>
                      <SelectWithText
                        title='Branch'
                        name='branch'
                        selectData={branchSelectDatas}
                        type='select-with-text'
                        value={branchId}
                        onChangeSelectWithText={(e) => {
                          setBranchId(e)
                        }}
                        id={''}
                      />
                    </div>
                  ) : (
                    <>
                      <div className='col-3'>
                        <SelectWithText
                          title='City'
                          name='city'
                          selectData={citySelectDataDonation}
                          type='select-with-text'
                          value={cityId}
                          onChangeSelectWithText={(e) => {
                            setCityId(e)
                          }}
                          onInputChange={(e) => {
                            getCities({ page: 0, size: 25, name: e })
                          }}
                          id={''}
                        />
                      </div>
                    </>
                  )}
                  <div className='col-2'>
                    <SelectInput
                      id='status'
                      title='Status'
                      type='select'
                      placeholder='Select Status...'
                      name='status'
                      value={status}
                      disabled={false}
                      required={false}
                      selectData={[
                        { label: 'capture', value: 'capture' },
                        { label: 'settlement', value: 'settlement' },
                        { label: 'pending', value: 'pending' },
                        { label: 'deny', value: 'deny' },
                        { label: 'cancel', value: 'cancel' },
                        { label: 'expire', value: 'expire' },
                        { label: 'refund', value: 'Refund' },
                        { label: 'partial_refund', value: 'partial_refund' },
                        { label: 'authorize', value: 'authorize' },
                        { label: 'waiting', value: 'waiting' },
                      ]}
                      onChangeSelect={(e) => {
                        setStatus(e.target.value)
                      }}
                    />
                  </div>
                  {/* <div className='col-2'>
                    <InputDatePicker
                      id={'start_date'}
                      title={'Start Date'}
                      name={'start_date'}
                      type={'date'}
                      value={startDate}
                      required={false}
                      onChangeDate={(e) => setStartDate(e)}
                    />
                  </div>
                  <div className='col-2'>
                    <InputDatePicker
                      id={'end_date'}
                      title={'End Date'}
                      name={'end_date'}
                      type={'date'}
                      value={endDate}
                      required={false}
                      onChangeDate={(e) => setEndDate(e)}
                    />
                  </div> */}
                  <div className='col-3'>
                    <SelectWithText
                      title='Program'
                      name='program'
                      selectData={programSelectDatas}
                      type='select-with-text'
                      value={programId}
                      onChangeSelectWithText={(e) => {
                        setProgramId(e)
                      }}
                      onInputChange={(e) => {
                        getPrograms({ page: 0, size: 25, title: e })
                      }}
                      id={''}
                    />
                  </div>
                  <div className='col-3'>
                    <Input
                      id={'affiliate_code'}
                      title={'Aff Code'}
                      type={'text'}
                      hidden={false}
                      placeholder={'Cari aff code...'}
                      name={'affiliate_code'}
                      value={affiliateCode}
                      disabled={false}
                      required={false}
                      onChange={(e) => setAffiliateCode(e.target?.value)}
                    />
                  </div>
                  <div className='col-3'>
                    <span>Channel Id</span>
                    <div className='mt-3 mb-4'>
                      <MultiSelect
                        key='Channel Id'
                        options={prospectChannelSelectDatas}
                        onClick={() => {
                          getProspectChannels({
                            page: 0,
                            size: 25,
                          })
                        }}
                        onChange={handleChannelChange}
                        value={prospectChannelSelected}
                        isSelectAll={true}
                        menuPlacement={'bottom'}
                      />
                    </div>
                  </div>
                  <div className='col-3'>
                    <span>Payment Method</span>
                    <div className='mt-3 mb-4'>
                      <MultiSelect
                        key='example_id'
                        options={paymentSelectDatas}
                        onClick={(e: any) => {
                          getPayments({
                            page: 0,
                            size: 10,
                            query: e,
                          })
                        }}
                        onChange={handlePaymentChange}
                        value={paymentSelected}
                        isSelectAll={true}
                        menuPlacement={'bottom'}
                      />
                    </div>
                  </div>
                  <div className='col-3'>
                    <SelectInput
                      id='reference'
                      title='Tipe Customer'
                      type='select'
                      placeholder='Select Tipe Customer...'
                      name='reference'
                      value={reference}
                      disabled={false}
                      required={false}
                      selectData={[
                        { label: 'Retail', value: 'customers' },
                        { label: 'Corporate', value: 'customers_corporate' },
                        { label: 'Community', value: 'customers_community' },
                      ]}
                      onChangeSelect={(e) => {
                        setReference(e.target.value)
                      }}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-4'>
                    <button
                      className='btn btn-primary me-5'
                      onClick={() => {
                        getDonation({
                          page: 0,
                          size: 10,
                          reference: reference ?? undefined,
                          start_date:
                            startDate === undefined ? undefined : dateQueryFormatter(startDate),
                          end_date: endDate === undefined ? undefined : dateQueryFormatter(endDate),
                          status: status,
                          customer_id: customerId?.value ?? undefined,
                          email: email ?? undefined,
                          province_id: totalDonationByBranch?.data?.province?.acronym,
                          city_id: cityId?.value ?? undefined,
                          fundraiser_id: fundraiserId?.value ?? undefined,
                          program_type: programType?.value ?? undefined,
                          program_id: programId?.value ?? undefined,
                          aff_code: affiliateCode ?? undefined,
                          branch_id: branchId?.value ?? undefined,
                          payment_id: paymentId?.value ?? undefined,
                          payment_selected: `${paymentValue}`,
                          channel_id: prospectChannelId?.value ?? undefined,
                          channel_selected: `${prospectChannelValue}`,
                          community_id:
                            currentUser?.data?.affiliate_code?.charAt(5) === '1' ||
                              currentUser?.data?.affiliate_code?.charAt(5) === '5'
                              ? currentUser?.data?.affiliate_code?.charAt(5)
                              : '',
                          class_code:
                            currentUser?.data?.affiliate_code?.charAt(5) !== '1' &&
                              currentUser?.data?.affiliate_code?.charAt(5) !== '5'
                              ? currentUser?.data?.affiliate_code
                              : '',
                          sort_amount: undefined
                        })
                      }}
                    >
                      Search
                    </button>
                    <button
                      className='btn btn-primary'
                      onClick={() => {
                        setStartDate(undefined)
                        setEndDate(undefined)
                        setStatus(undefined)
                        setCustomerId(undefined)
                        setEmail(undefined)
                        setCityId(undefined)
                        setFundraiserId(undefined)
                        setProgramType(undefined)
                        setProgramId(undefined)
                        setAffiliateCode(undefined)
                        setBranchId(undefined)
                        setPaymentId(undefined)
                        setPaymenValue(undefined)
                        setPaymentSelected(null)
                        setProspectChannelId(undefined)
                        setProspectChannelValue(undefined)
                        setProspectChannelSelected(undefined)
                        setReference(undefined)
                        getDonation({
                          page: 0,
                          size: 10,
                          reference: undefined,
                          start_date: undefined,
                          end_date: undefined,
                          status: undefined,
                          customer_id: undefined,
                          email: undefined,
                          province_id: totalDonationByBranch?.data?.province?.acronym,
                          city_id: undefined,
                          fundraiser_id: undefined,
                          program_type: undefined,
                          program_id: undefined,
                          aff_code: undefined,
                          branch_id: undefined,
                          payment_id: undefined,
                          channel_id: undefined,
                          community_id:
                            currentUser?.data?.affiliate_code?.charAt(5) == '1' ||
                              currentUser?.data?.affiliate_code?.charAt(5) == '5'
                              ? currentUser?.data?.affiliate_code?.charAt(5)
                              : '',
                          class_code:
                            currentUser?.data?.affiliate_code?.charAt(5) !== '1' &&
                              currentUser?.data?.affiliate_code?.charAt(5) !== '5'
                              ? currentUser?.data?.affiliate_code
                              : '',
                          sort_amount: undefined
                        })
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {exportLoading ? (
            <div className='alert alert-info d-flex align-items-center p-5'>
              <div className='d-flex flex-column'>
                <h4 className='mb-1 text-info'>Report on progress</h4>
                <span>Generating your report please wait....</span>
              </div>
            </div>
          ) : null}

          <div className='card-header border-0 pt-6'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Donation</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>Donation Management</span>
            </h3>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={(e) => {
                  e.preventDefault()
                  if (!exportLoading) {
                    const tokenLocal = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
                    const token = JSON.parse(tokenLocal) as AuthModel
                    setExportLoading(true)
                    const params = {
                      company_id: currentUser?.data?.company?.id,
                      reference: reference ?? undefined,
                      program_id: programId?.value ?? undefined,
                      fundraiser_id: fundraiserId?.value ?? undefined,
                      start_date:
                        startDate === undefined ? undefined : dateQueryFormatter(startDate),
                      end_date: endDate === undefined ? undefined : dateQueryFormatter(endDate),
                      customer_id: customerId?.value ?? undefined,
                      status: status,
                      province_id: totalDonationByBranch?.data?.province?.acronym,
                      city_id: cityId?.value ?? undefined,
                      aff_code: affiliateCode ?? undefined,
                      branch_id: branchId?.value ?? undefined,
                      program_type: programType?.value ?? undefined,
                      payment_id: paymentId?.value ?? undefined,
                      payment_selected: `${paymentValue}`,
                      prospect_channel_id: prospectChannelId?.value ?? undefined,
                      channel_selected: `${prospectChannelValue}`,
                      community_id:
                        currentUser?.data?.affiliate_code?.charAt(5) == '1' ||
                          currentUser?.data?.affiliate_code?.charAt(5) == '5'
                          ? currentUser?.data?.affiliate_code?.charAt(5)
                          : undefined,
                      class_code:
                        currentUser?.data?.affiliate_code?.charAt(5) !== '1' &&
                          currentUser?.data?.affiliate_code?.charAt(5) !== '5'
                          ? currentUser?.data?.affiliate_code
                          : undefined,
                    }
                    fetch(
                      `${process.env.REACT_APP_API_PROGRAM_URL
                      }/export-donations?${new URLSearchParams(params)}`,
                      {
                        method: 'GET',
                        headers: {
                          'Content-Type':
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          Authorization: `Bearer ${token?.data?.accessToken}`,
                        },
                      }
                    )
                      .then((response) => response.blob())
                      .then((blob) => {
                        const url = window.URL.createObjectURL(new Blob([blob]))

                        const link = document.createElement('a')
                        link.href = url
                        link.download = `Export_Donation_${Date.now()}.xlsx`

                        document.body.appendChild(link)

                        link.click()

                        link.parentNode.removeChild(link)
                        setExportLoading(false)
                      })
                      .catch(() => {
                        setExportLoading(false)
                      })
                  } else {
                    toast.error('Exporting in progress...')
                  }
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                Export Excel
              </button>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              {donationLoading ? <LoadingComponent /> : <></>}
              <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
                <thead>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    <th>Donatur</th>
                    <th>Program</th>
                    <th>Amount</th>
                    <th>Kode Unik</th>
                    <th>Referal Code</th>
                    <th>Province</th>
                    <th>City</th>
                    <th>Transfer Date</th>
                    <th>Status</th>
                    <th>E-Kwitansi</th>
                    <th>BSZ</th>
                    <th>Bukti Pembayaran</th>
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-semibold'>
                  {donationItems?.length === 0 ? (
                    <tr>
                      <td colSpan={6}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No Properties Found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    donationItems?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className='d-flex flex-column'>
                              <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                                {item.customer_name ?? '-'}
                              </a>
                              <span>{item.customer_email ?? '-'}</span>
                              <span>{item.customer_phone ?? '-'}</span>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex flex-column'>
                              <span>{item.program_title}</span>
                              <span className='badge badge-success'>{item?.program_type}</span>
                            </div>
                          </td>
                          <td>{generateCurrencyFormat(item.amount)}</td>
                          <td>{generateCurrencyFormat(item.unique_code)}</td>
                          <td>
                            {item?.affiliate_code === '' ? item?.aff_code : item?.affiliate_code}
                          </td>
                          <td>{item?.province_name ?? '-'}</td>
                          <td>{item?.city_name ?? '-'}</td>
                          <td>{timeDateFormatter(Date.parse(item?.transfer_date?.toString()))}</td>
                          <td>{generateStatusBadge(item.status)}</td>
                          <td>
                            <span
                              className='badge badge-success'
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={(e) => {
                                // setSelectedDonation(item);
                                navigate(
                                  `/apps/donation/donation-management/invoice?donation_id=${item.id}`
                                )
                              }}
                            >
                              E-Kwitansi
                            </span>
                          </td>
                          <td>
                            <span
                              className='badge badge-success'
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={(e) => {
                                // setSelectedDonation(item);
                                navigate(
                                  `/apps/donation/donation-management/bukti-setor-zakat?donation_id=${item.id}`
                                )
                              }}
                            >
                              Bukti Setor Zakat
                            </span>
                          </td>
                          <td>
                            <span
                              className='badge badge-success'
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={(e) => {
                                // setSelectedDonation(item);
                                navigate(
                                  `/apps/donation/donation-management/bukti-setor-zakat?donation_id=${item.id}`
                                )
                              }}
                            >
                              {item?.proof_of_payment}
                            </span>
                          </td>
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
            </div>

            <div className='card-footer'>
              <ReactPaginate
                previousLabel={'«'}
                nextLabel={'»'}
                breakLabel={'...'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                pageCount={donationModel?.data?.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={10}
                onPageChange={handlePageClick}
                containerClassName={'pagination pagination-sm m-0 float-right'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
          </div>
        </KTCard>
      ) : null}
    </>
  )
}

export default DashboardAdminBranchScreen
